import React from "react";

class AddUser extends React.Component {

    userAdd = {}
    constructor(props) {
        super(props)
        this.state = {
            first_name: "", last_name: "", bio: "", age: 1, isHappy: false
        }
    }

    render() {
        return (<form ref={(el) => this.addForm = el}>
                <input placeholder="Name" name="first_name"
                       onChange={(e) => this.setState({first_name: e.target.value})}/>
                <input placeholder="Last Name" name="last_name"
                       onChange={(e) => this.setState({last_name: e.target.value})}/>
                <textarea placeholder="Bio" name="bio"
                          onChange={(e) => this.setState({bio: e.target.value})}></textarea>
                <input placeholder="Age" name="age" onChange={(e) => this.setState({age: e.target.value})}/>
                <label htmlfor="IsHappy">IsHappy ?</label>
                <input type="checkbox" id="IsHappy" name="isHappy"
                       onChange={(e) => this.setState({IsHappy: e.target.checked})}/>
                <button type="button" onClick={() => {

                    this.userAdd = {
                        first_name: this.state.first_name,
                        last_name: this.state.last_name,
                        bio: this.state.bio,
                        age: this.state.age,
                        IsHappy: this.state.IsHappy,
                    }

                    if (this.props.user) {
                        this.userAdd.id = this.props.user.id
                    }

                    this.props.onAdd(this.userAdd)
                    this.addForm.reset()
                }}>Добавить
                </button>
            </form>)
    }
}

export default AddUser