import React from "react";
import { IoCloseCircleSharp, IoHammerSharp } from 'react-icons/io5'
import AddUser from './adduser'
class User extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            editForm: false
        }
    }

    user = this.props.currentUser
    render() {
        return (
            <div className='user'>
                <IoCloseCircleSharp onClick={() => this.props.onDelete(this.user.id)} className="delete-icon"/>
                <IoHammerSharp  onClick={() => {
                    this.setState({
                        editForm: !this.state.editForm
                    })
                }} className="edit-icon"/>
                <h3>{this.user.first_name} {this.user.last_name}</h3>
                <p><img src={this.user.avatar}/></p>
                <p>{this.user.email}</p>
                <b>{this.user.isHappy ? 'happy' : 'unhappy'}</b>

                {this.state.editForm && <AddUser onAdd={this.props.onEdit} user={this.user}/>}
            </div>
        )
    }
}

export default User