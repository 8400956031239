import React from "react";
import Header from "./components/header";
import Users from "./components/users";
import AddUser from "./components/adduser"
import axios from "axios"

const baseUrl = "https://reqres.in/api/users?page=1"

class App extends React.Component {

    constructor(props) {
        super(props)

        axios.get(baseUrl).then((res) => {
            this.setState({users: res.data.data})
        })


        this.state = {
            users: [
                // {
                //     id: 1,
                //     firstname: 'Bob',
                //     lastname: 'Marley',
                //     bio: 'Lorem ipsum bla bla bla',
                //     age: 25,
                //     isHappy: true
                // },
                // {
                //     id: 2,
                //     firstname: 'John',
                //     lastname: 'Doe',
                //     bio: 'Lorem ipsum bla bla bla2',
                //     age: 35,
                //     isHappy: false
                // },
            ]
        }

        this.addUser = this.addUser.bind(this)
        this.deleteUser = this.deleteUser.bind(this)
        this.editUser = this.editUser.bind(this)
    }

    render() {
        return (<div>
            <Header titleProp={'Список пользователей'}/>
            <main>
                <Users users={this.state.users} onDelete={this.deleteUser} onEdit={this.editUser}/>
            </main>
            <aside>
                <AddUser onAdd={this.addUser}/>
            </aside>
        </div>)
    }

    addUser(user) {
        const id = this.state.users.length + 1
        this.setState({users: [...this.state.users, {id, ...user}]})
    }

    deleteUser(id) {
        this.setState({
            users: this.state.users.filter((el) => el.id !== id)
        })
    }

    editUser(user) {
        let allUsers = this.state.users
        allUsers[user.id - 1] = user
        this.setState({users: []}, () => {
                this.setState({users: [...allUsers] })
        })
    }
}

export default App